var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-form',[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('section',{staticClass:"w-full flex flex-col"},[_c('div',{staticClass:"w-full flex gap-5 justify-between mb-2"},[_c('input-required',{staticClass:"col-9 padding-0",attrs:{"label":_vm.$t('sectionPharmaceuticals.description'),"disabled":true},model:{value:(_vm.value.Descricao),callback:function ($$v) {_vm.$set(_vm.value, "Descricao", $$v)},expression:"value.Descricao"}}),_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.inativarForma'
                      ),"disabled":""},model:{value:(_vm.value.InativarForma),callback:function ($$v) {_vm.$set(_vm.value, "InativarForma", $$v)},expression:"value.InativarForma"}})],1)],1),_c('div',{staticClass:"w-full flex border__top border__bottom",staticStyle:{"font-size":"small"}},[_c('div',{staticClass:"flex col-4 padding-0"},[_c('div',{staticClass:" 2xl:w-20 p-2 col-3 padding-0 border__right",class:{
              }},[_c('span',{staticClass:"text-red-500 required"},[_vm._v(_vm._s(_vm.$t("sectionPharmaceuticals.type"))),_c('span',{staticStyle:{"color":"#cf0209"}},[_vm._v(" *")])])]),_c('div',{staticClass:"col-9 padding-right-0 border__right"},[_c('radioButton',{attrs:{"disabled":"","options":_vm.options,"label":_vm.$t('sectionPharmaceuticals.type'),"rules":"required","stacked":""},model:{value:(_vm.value.TipoForma),callback:function ($$v) {_vm.$set(_vm.value, "TipoForma", $$v)},expression:"value.TipoForma"}})],1)]),_c('div',{staticClass:"col-8 padding-0"},[_c('div',{staticClass:"flex-1 flex flex-col col-12 padding-0 border__right",class:{
                border__right: _vm.$mq.desktop,
              }},[_c('div',{staticClass:"flex flex-col p-2 border__bottom gap-1 "},[_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.selectSuggestedQuantity'
                    ),"disabled":""},model:{value:(_vm.value.SelecionarQuantidadeSugerida),callback:function ($$v) {_vm.$set(_vm.value, "SelecionarQuantidadeSugerida", $$v)},expression:"value.SelecionarQuantidadeSugerida"}}),_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.multiplyComposition'
                    ),"disabled":""},model:{value:(_vm.value.MultiplicarComposicao),callback:function ($$v) {_vm.$set(_vm.value, "MultiplicarComposicao", $$v)},expression:"value.MultiplicarComposicao"}})],1),_c('div',{staticClass:"flex flex-col p-2 border__bottom gap-1"},[_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.liquidHomeopathy'
                    ),"disabled":""},model:{value:(_vm.value.HomeopatiaLiquida),callback:function ($$v) {_vm.$set(_vm.value, "HomeopatiaLiquida", $$v)},expression:"value.HomeopatiaLiquida"}}),_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.doNotDeductFromTheVehicle'
                    ),"disabled":""},model:{value:(_vm.value.NaoDeduzirVeiculo),callback:function ($$v) {_vm.$set(_vm.value, "NaoDeduzirVeiculo", $$v)},expression:"value.NaoDeduzirVeiculo"}})],1),_c('div',{staticClass:"flex flex-col p-2  border__bottom"},[_c('CheckboxDefault',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.convertPackageVolume'
                    ),"disabled":""},model:{value:(_vm.value.ConverteVolumeEmbalagem),callback:function ($$v) {_vm.$set(_vm.value, "ConverteVolumeEmbalagem", $$v)},expression:"value.ConverteVolumeEmbalagem"}})],1)]),_c('div',{staticClass:"w-full xl:w-auto p-2 border__right border__bottom",class:{
              }},[_c('span',{staticClass:"text-red-500"},[_vm._v(" Cálculo da embalagem "),(_vm.value.TipoForma == '5' || _vm.value.TipoForma == '6')?_c('span',[_vm._v(" * ")]):_vm._e()])]),_c('div',{staticClass:"flex flex-col p-2 FormsRadio border__right"},[_c('radioButton',{attrs:{"options":_vm.optionsCalculoEmbalagem,"label":"Cálculo da embalagem","stacked":"","rules":{required : _vm.value.TipoForma == '5' ||
                                    _vm.value.TipoForma == '6'},"disabled":""},model:{value:(_vm.value.CalculoEmbalagem),callback:function ($$v) {_vm.$set(_vm.value, "CalculoEmbalagem", $$v)},expression:"value.CalculoEmbalagem"}})],1)])]),_c('div',{staticClass:"w-full flex border__bottom",class:{
            }},[_c('div',{staticClass:"2xl:w-1/2  p-2 col-6 ",class:{
                border__bottom: _vm.$mq.notebook,
              },staticStyle:{"padding-left":"0!important"}},[_c('input-default',{attrs:{"label":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.use'),"rules":{max:20},"max":"20","disabled":true},model:{value:(_vm.value.Uso),callback:function ($$v) {_vm.$set(_vm.value, "Uso", $$v)},expression:"value.Uso"}})],1),_c('div',{staticClass:"w-1/2 flex p-2 FormsRadio border__right"},[_c('div',{staticClass:"border__right w-full 2xl:w-60 text-red-500 col-5 mb-2 text-center  flex 2xl:items-start"},[_c('span',[_vm._v(_vm._s(_vm.$t( "sectionPharmaceuticals.generalPharmaceuticals.typeOfUse" ))+" *")])]),_c('radioButton',{staticClass:"col-7",attrs:{"options":_vm.optionsTipoUso,"label":"Tipo Uso","stacked":"","rules":"required","disabled":""},model:{value:(_vm.value.TipoUso),callback:function ($$v) {_vm.$set(_vm.value, "TipoUso", $$v)},expression:"value.TipoUso"}})],1)]),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-12 gap-4 mt-3"},[_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('input-default',{attrs:{"label":"P.O.P","rules":{max:10},"mask":"XXXXXXXXXX","disabled":true,"max":"10"},model:{value:(_vm.value.POP),callback:function ($$v) {_vm.$set(_vm.value, "POP", $$v)},expression:"value.POP"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-6 "},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.printProductAnalysisFields'
                      ),"disabled":""},model:{value:(_vm.value.ImprimeCamposAnaliseProduto),callback:function ($$v) {_vm.$set(_vm.value, "ImprimeCamposAnaliseProduto", $$v)},expression:"value.ImprimeCamposAnaliseProduto"}}),_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.SelecionarVolumeAutomatico'
                      ),"disabled":""},model:{value:(_vm.value.SelecionarVolumeAutomatico),callback:function ($$v) {_vm.$set(_vm.value, "SelecionarVolumeAutomatico", $$v)},expression:"value.SelecionarVolumeAutomatico"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid justify-between grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.validityDays'
                      ),"hasText":true,"text":"(dias)","type":"number","mask":"XXXX","rules":{positive:true,integer:true},"disabled":true},model:{value:(_vm.value.Validade),callback:function ($$v) {_vm.$set(_vm.value, "Validade", $$v)},expression:"value.Validade"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.quantityMlDrops'
                      ),"hasText":true,"text":"(ML/Gotas)","rules":{positive:true ,decimal:[10,2], max:8},"type":"number","disabled":true},model:{value:(_vm.value.Quantidade),callback:function ($$v) {_vm.$set(_vm.value, "Quantidade", $$v)},expression:"value.Quantidade"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-7"},[_c('input-default',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.FatorPerda'
                      ),"type":"number","rules":{positive:true,decimal:[24,5],max:24},"disabled":true},model:{value:(_vm.value.FatorPerda),callback:function ($$v) {_vm.$set(_vm.value, "FatorPerda", $$v)},expression:"value.FatorPerda"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-5"},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.ImprimirUnidadeRotulo'
                      ),"disabled":""},model:{value:(_vm.value.imprimirUnidadeRotulo),callback:function ($$v) {_vm.$set(_vm.value, "imprimirUnidadeRotulo", $$v)},expression:"value.imprimirUnidadeRotulo"}}),_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                          'sectionPharmaceuticals.generalPharmaceuticals.AplicaFatorPerdaQSP'
                        ),"disabled":true},model:{value:(_vm.value.AplicaFatorPerdaQSP),callback:function ($$v) {_vm.$set(_vm.value, "AplicaFatorPerdaQSP", $$v)},expression:"value.AplicaFatorPerdaQSP"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('Dropdown',{attrs:{"Items":_vm.dropdownItemsManipulador,"valueDefault":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.manipulator'),"label":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.manipulator'),"disabled":true},model:{value:(_vm.value.Manipulador),callback:function ($$v) {_vm.$set(_vm.value, "Manipulador", $$v)},expression:"value.Manipulador"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.hourFormulas'
                      ),"type":"number","rules":{positive:true,integer:true},"disabled":true},model:{value:(_vm.value.FormulasHora),callback:function ($$v) {_vm.$set(_vm.value, "FormulasHora", $$v)},expression:"value.FormulasHora"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-8"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.labelDescription'
                      ),"rules":{max:50},"max":"50","disabled":true},model:{value:(_vm.value.DescricaoRotulo),callback:function ($$v) {_vm.$set(_vm.value, "DescricaoRotulo", $$v)},expression:"value.DescricaoRotulo"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('input-default',{attrs:{"label":_vm.$t(
                        'sectionPharmaceuticals.generalPharmaceuticals.minimumQsp'
                      ),"hasText":true,"text":"%","type":"number","rules":{positive:true,decimal:[24,5]},"disabled":true},model:{value:(_vm.value.QspMinimo),callback:function ($$v) {_vm.$set(_vm.value, "QspMinimo", $$v)},expression:"value.QspMinimo"}})],1)])]),_c('div',{staticClass:"col-span-12"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('Dropdown',{attrs:{"Items":_vm.dropdownItemsVeiculo,"valueDefault":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.vehicle'),"label":_vm.$t('sectionPharmaceuticals.generalPharmaceuticals.vehicle'),"disabled":true},model:{value:(_vm.value.Veiculo),callback:function ($$v) {_vm.$set(_vm.value, "Veiculo", $$v)},expression:"value.Veiculo"}})],1),_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('div',{staticClass:"flex flex-col lg:pl-5"},[_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                          'sectionPharmaceuticals.generalPharmaceuticals.formMonitoredWeighing'
                        ),"disabled":true},model:{value:(_vm.value.PesagemMonitoradaForma),callback:function ($$v) {_vm.$set(_vm.value, "PesagemMonitoradaForma", $$v)},expression:"value.PesagemMonitoradaForma"}}),_c('CheckboxDefault',{staticClass:"justify-end",attrs:{"label":_vm.$t(
                          'sectionPharmaceuticals.generalPharmaceuticals.calculateDensity'
                        ),"disabled":true},model:{value:(_vm.value.CalcularDensidade),callback:function ($$v) {_vm.$set(_vm.value, "CalcularDensidade", $$v)},expression:"value.CalcularDensidade"}})],1)])])]),_c('div',{staticClass:"col-span-12 lg:col-span-6"},[_c('div',[_c('input-default',{attrs:{"label":_vm.$t(
                      'sectionPharmaceuticals.generalPharmaceuticals.CodLab'
                    ),"rules":{max:2},"max":"2","disabled":true},model:{value:(_vm.value.LabEspCustos),callback:function ($$v) {_vm.$set(_vm.value, "LabEspCustos", $$v)},expression:"value.LabEspCustos"}})],1)])])])]),_c('div',{staticClass:"col-span-12 lg:col-span-4"},[_c('div',{staticClass:"w-80 flex flex-col",class:{ 'w-full': _vm.$mq.mobile }},[_c('div',{staticClass:"imagePreviewWrapper background__color button__border__bottom h-72 rounded relative",style:({ 'background-image': ("url(" + (_vm.value.Imagem) + ")") })})])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }