<template>
  <section class="w-full">
    <header-register :showButtonView="false"
      >{{ $t("sectionPharmaceuticals.titlePharmaceuticals") }}
    </header-register>
    <main class="w-full">
      <ManagePharmaceuticalsView
        :tabIndex.sync="tabIndex"
        :showFormView="showFormView"
      />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManagePharmaceuticalsView from "@/components/farmaceuticas/components/view/ManagePharmaceuticalsView.vue";

export default {
  name: "PharmaceuticalsView",
  components: {
    HeaderRegister,
    ManagePharmaceuticalsView,
  },
  data() {
    return {
      tabIndex: 0,
      showFormView: false,
    };
  },
};
</script>
