var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-form',[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-4"},[_c('div',{staticClass:"grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12"},[_c('input-default',{attrs:{"label":_vm.$t('sectionPharmaceuticals.valuePharmaceuticals.minimumValue'),"disabled":"true"},model:{value:(_vm.value.valorMinimo),callback:function ($$v) {_vm.$set(_vm.value, "valorMinimo", $$v)},expression:"value.valorMinimo"}})],1),_c('div',{staticClass:"col-span-12"},[_c('input-default',{attrs:{"label":_vm.$t(
                  'sectionPharmaceuticals.valuePharmaceuticals.additionalCharge'
                ),"disabled":"true"},model:{value:(_vm.value.valorMaximo),callback:function ($$v) {_vm.$set(_vm.value, "valorMaximo", $$v)},expression:"value.valorMaximo"}})],1)])])]),_c('div',{staticClass:"w-full flex flex-col lg:flex-row items-start border__top border__bottom my-3"},[_c('div',{staticClass:"w-full lg:w-1/2 lg:pr-4",class:{
          border__right: _vm.$mq.desktop,
        }},[_c('div',{staticClass:"w-full flex flex-col py-4"},[_c('div',{staticClass:"flex w-full",staticStyle:{"justify-content":"space-between"}},[_c('h3',{staticClass:"text-left text-red-500 text-lg"},[_vm._v(" "+_vm._s(_vm.$t( "sectionPharmaceuticals.valuePharmaceuticals.rangeMinimumValue" ))+" ")])]),_c('div',{staticClass:"w-full flex gap-3",class:{
              flex__direction: _vm.$mq.mobile,
            }},[_c('Table',{attrs:{"columns":_vm.tableQtdeInicialColumns}},_vm._l((_vm.value.faixaValor),function(row){return _c('tr',{key:row},[_c('td',[_c('div',[_vm._v(" "+_vm._s(row.quantidadeInicial)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(row.quantidadeFinal)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(row.valorMinimo)+" ")])])])}),0)],1)])]),_c('div',{staticClass:"w-full lg:w-1/2 lg:pl-4"},[_c('div',{staticClass:"w-full flex flex-col py-4"},[_c('div',{staticClass:"flex w-full",staticStyle:{"justify-content":"space-between"}},[_c('h3',{staticClass:"text-left text-red-500 text-lg"},[_vm._v(" "+_vm._s(_vm.$t( "sectionPharmaceuticals.valuePharmaceuticals.marginValueShape" ))+" ")])]),_c('div',{staticClass:"w-full flex gap-3",class:{
              flex__direction: _vm.$mq.mobile,
            }},[_c('Table',{attrs:{"columns":_vm.tableValorInicialColumns}},_vm._l((_vm.value.margemValor),function(row){return _c('tr',{key:row},[_c('td',[_c('div',[_vm._v(" "+_vm._s(row.valorInicial)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(row.valorFinal)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(row.percentualMargem)+" ")])])])}),0)],1)])])]),_c('div',{staticClass:"w-full lg:w-1/2"},[_c('Dropdown',{attrs:{"Items":_vm.dropdownItemsNcm,"valueDefault":"NCM/SH","label":"NCM/SH","disabled":"true"},model:{value:(_vm.value.Ncm),callback:function ($$v) {_vm.$set(_vm.value, "Ncm", $$v)},expression:"value.Ncm"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }