<template>
  <div>
  <div class="grid grid-cols-1 md:grid-cols-12 gap-4" v-if="edit">
    <div class="col-span-8">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <input-default
            :label="
              $t('sectionPharmaceuticals.essaysPharmaceuticals.testDescription')
            "
            v-model="descricao"
          />
        </div>
      </div>
      <footer
          class="w-full flex items-center justify-start gap-5 my-10"
        >
          <Button
            class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
            buttonText="Confirmar"
            :onClick="updateEssay"
          />
          <Button
            class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
            buttonText="Cancelar"
            :onClick="() => { 
                              edit = false; 
                              descricao = null;
                              posicao=null
                            }"
          />
        </footer>
    </div>
  </div>
    <b-table :items="value.FormaFarmaceuticaEnsaio" :fields="fields" filter="false" striped responsive="lg">
      <template #cell(descricao)="data">
          {{ data.item.descricao }}
      </template>
      <template #cell(ações)="data">
        <div class="flex justify-center" v-if="showActions">
          <b-button
            class="bg-transparent border-0"
            size="sm"
            @click="editEssay(data.item)"
          >
            <EditIcon class="w-4 icon__actions" />
          </b-button>
          <b-button
            class="bg-transparent border-0"
            size="sm"
            @click="deleteEssay(data.item)"
          >
            <DeleteIcon class="w-4  icon__actions" />
          </b-button>
        </div>
      </template>
    </b-table>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      :closeModal="closeModal"
      :buttonOptions="true"
      :modalText="$t('commons.modalTextDelete')"
      modalFeaturedText="Ensaio?"
    />
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import Modal from "@core/components/commons/modal/Modal";
import InputDefault from "@core/components/commons/inputs/InputDefault.vue";
import Button from "@core/components/commons/button/Button";

import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";
export default {
  name: "ListEssays",
  components: {
    BTable,
    EditIcon,
    DeleteIcon,
    Modal,
    InputDefault,
    Button
  },
  props: {
    showActions: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required:true
    }
  },
  data() {
    return {
      fields: [{ key: "descricao",label:"Descrição", sortable: true }, { key: "ações" }],
      modalIsOpen: false,
      edit: false,
      descricao: null,
      posicao:null,
    };
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    deleteEssay (essay) {
      if (essay.id != null) {
        this.$set(this.value.FormaFarmaceuticaEnsaio, this.value.FormaFarmaceuticaEnsaio.indexOf(essay), {
          descricao: essay.descricao,
          id: essay.id,
          excluido:true
        })
      }
      else {
        this.value.FormaFarmaceuticaEnsaio.splice(this.value.FormaFarmaceuticaEnsaio.indexOf(essay),1);
      }
    },
    editEssay (essay) {
      this.edit = true;
      this.posicao = this.value.FormaFarmaceuticaEnsaio.indexOf(essay)
      this.descricao = essay.descricao;
    },
    updateEssay () {
      if (this.value.FormaFarmaceuticaEnsaio[this.posicao].id != null) {
        this.$set(this.value.FormaFarmaceuticaEnsaio, this.posicao, {
        descricao: this.descricao,
          id: this.value.FormaFarmaceuticaEnsaio[this.posicao].id,
          excluido: false
        })
      }
      else {
        this.$set(this.value.FormaFarmaceuticaEnsaio, this.posicao, {
        descricao: this.descricao,excluido: false})
      }
      this.descricao = null;
      this.posicao = null;
      this.edit = false;
    }
  },
};
</script>

<style scoped>
.icon__actions {
  fill: red;
}
</style>
