<template>
  <section>
    <b-form>
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div class="col-span-4">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <input-default
                :label="
                  $t('sectionPharmaceuticals.valuePharmaceuticals.minimumValue')
                "
                v-model="value.valorMinimo"
                disabled="true"
              />
            </div>
            <div class="col-span-12">
              <input-default
                :label="
                  $t(
                    'sectionPharmaceuticals.valuePharmaceuticals.additionalCharge'
                  )
                "
                disabled="true"
                v-model="value.valorMaximo"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full flex flex-col lg:flex-row items-start border__top border__bottom my-3"
      >
        <div
          class="w-full lg:w-1/2 lg:pr-4"
          :class="{
            border__right: $mq.desktop,
          }"
        >
          <div class="w-full flex flex-col py-4" >
            <div class="flex w-full" style="justify-content: space-between;">
              <h3 class="text-left text-red-500 text-lg">
                {{
                  $t(
                    "sectionPharmaceuticals.valuePharmaceuticals.rangeMinimumValue"
                  )
                }}
              </h3>
            </div>
            <div
              class="w-full flex gap-3"
              :class="{
                flex__direction: $mq.mobile,
              }"
            >
              <Table :columns="tableQtdeInicialColumns">
                <tr v-for="row in value.faixaValor" :key="row">
                  <td>
                    <div>
                        {{ row.quantidadeInicial }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ row.quantidadeFinal }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ row.valorMinimo }}
                    </div>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 lg:pl-4">
          <div class="w-full flex flex-col py-4">
            <div class="flex w-full" style="justify-content: space-between;">
            <h3 class="text-left text-red-500 text-lg">
              {{
                $t(
                  "sectionPharmaceuticals.valuePharmaceuticals.marginValueShape"
                )
              }}
            </h3>
            </div>
            <div
              class="w-full flex gap-3"
              :class="{
                flex__direction: $mq.mobile,
              }"
            >
              <Table :columns="tableValorInicialColumns">
                <tr v-for="row in value.margemValor" :key="row">
                  <td>
                    <div>
                        {{ row.valorInicial }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ row.valorFinal }}
                    </div>
                  </td>
                  <td>
                    <div>
                      {{ row.percentualMargem }}
                    </div>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full lg:w-1/2">
        <Dropdown 
          :Items="dropdownItemsNcm"
          valueDefault="NCM/SH"
          label="NCM/SH"
          disabled="true"
          v-model="value.Ncm"
          >
        </Dropdown>
      </div>
    </b-form>
  </section>
</template>

<script>
import { required } from "@validations";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import Table from "@core/components/commons/table/Table";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

import { BForm } from "bootstrap-vue";

export default {
  name: "ValuePharmaceuticalsInformation",
  components: {
    BForm,
    InputDefault,
    Table,
    Dropdown
  },
  props: {
    value: {
      required: true,
      type:Object,
    }
  },
  data() {
    return {
      tableQtdeInicialColumns: ["Quant. inicial","Quant. final","Valor mínimo"],
      tableValorInicialColumns: ["Valor inicial","Valor final","Margem %"],
      required,
      edit:null,
      dropdownItemsNcm: null,
      ValorMinimoEdit: null,
      QndtFinalEdit:null,
      QndtInicialEdit:null,
    };
  },
  created () {
    this.ObterSeletorNcm();
  },
  methods: {
    ObterSeletorNcm() {
      this.$http({
        url: `/ncm/obter-seletor-ncm`,
        method: "GET"
      }).then((res) => {
          this.dropdownItemsNcm = res.data;
        })
    },
  }
};
</script>

<style scoped>
.border__top {
  border-top: 1px solid rgba(200, 200, 200, 0.5);
}

.border__right {
  border-right: 1px solid rgba(200, 200, 200, 0.5);
}

.border__bottom {
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}

.flex__direction {
  flex-direction: column;
}
</style>
