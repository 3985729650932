<template>
  <table>
    <thead>
      <tr>
        <th v-for="column in columns" :key="column">{{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script type="module">
export default {
  name: "Table",
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
table {
  background: #efefef;
  width: 100%;
  border-bottom: 3px solid black;
}
table thead tr {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
}
table thead tr th {
  padding: 0.7rem;
  text-align: left;
  font-size: 0.9rem;
  font-weight: bold;
  border-right: 2px solid rgba(200, 200, 200, 0.8);

  border-bottom: 2px solid rgba(200, 200, 200, 0.8);
}
table tbody tr td {
  padding: 0.5rem 0.7rem;
  text-align: left;
  font-size: 0.9rem;
  border-right: 2px solid rgba(200, 200, 200, 0.8);

  border-bottom: 2px solid rgba(200, 200, 200, 0.8);
}

</style>
