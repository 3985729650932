<template>
  <div class="w-full flex flex-col justify-between">
    <section class="-mt-4">
      <b-tabs class="w-full" v-model="tabIndexComponent">
        <b-tab title="Geral">
          <GeneralPharmaceuticalsInformation v-model="viewModel" class="mt-4" />
        </b-tab>
        <b-tab title="Ensaios">
          <EssaysInformationPharmaceuticals
            class="mt-4"
            v-model="viewModel"
          />
        </b-tab>
        <b-tab title="Valores">
          <ValuePharmaceuticalsInformation  v-model="viewModel" class="mt-4" />
        </b-tab>
      </b-tabs>
    </section>
    <footer
      v-if="tabIndexComponent !== 1"
      class="w-full flex items-center justify-start gap-5 my-10"
    >
      <Button
        class="bg-gray-900 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-gray-700 transition-all duration-300"
        buttonText="Voltar"
        :onClick="redirectUrl"
      />
    </footer>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralPharmaceuticalsInformation from "./GeneralPharmaceuticalsInformation";
import EssaysInformationPharmaceuticals from "./EssaysInformationPharmaceuticals";
import ValuePharmaceuticalsInformation from "./ValuePharmaceuticalsInformation";
import Button from "@core/components/commons/button/Button";

export default {
  name: "ManagePharmaceuticalsCreate",
  components: {
    BTabs,
    BTab,
    GeneralPharmaceuticalsInformation,
    EssaysInformationPharmaceuticals,
    ValuePharmaceuticalsInformation,
    Button,
  },
  props: {
    tabIndex: {
      type: Number,
    },
  },
  data() {
    return {
      tabIndexComponent: this.tabIndex,
      viewModel: {
        Imagem:null,
        TipoForma: null,
        Descricao: null,
        SelecionarQuantidadeSugerida: false,
        MultiplicarComposicao:false,
        HomeopatiaLiquida:false,
        NaoDeduzirVeiculo:false,
        ConverteVolumeEmbalagem: false,
        CalculoEmbalagem: null,
        Uso: null,
        TipoUso: null,
        POP: null,
        ImprimeCamposAnaliseProduto: false,
        Validade: null,
        Quantidade: null,
        Manipulador: null,
        FormulasHora: null,
        FatorPerda:null,
        DescricaoRotulo: null,
        QspMinimo: null,
        Veiculo: null,
        InativarForma: false,
        LabEspCustos:null,
        PesagemMonitoradaForma: false,
        CalcularDensidade: false,
        ImprimirUnidadeRotulo: false,
        SelecionarVolumeAutomatico:false,
        AplicaFatorPerdaQSP: false,
        FormaFarmaceuticaEnsaio: [],
        valorMinimo: null,
        valorMaximo: null,
        Ncm: null,
        faixaValor: [],
        margemValor:[],
      }
    };
  },
  created() {
    this.RecuperarForma(this.$router.currentRoute.params.id);
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
    },
    redirectUrl() {
      return this.$router.push("/farmaceuticas");
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    RecuperarForma (id) {
     this.$http({
      url: `/formaFarmaceutica/obter/${id}`,
      method: "Get"
    }).then((response) => {
        this.viewModel.TipoForma= response.data.tipoForma;
        this.viewModel.Descricao= response.data.descricao;
        this.viewModel.SelecionarQuantidadeSugerida= response.data.selecionaQtdeSugerida;
        this.viewModel.MultiplicarComposicao=response.data.multiplicaComposicao;
        this.viewModel.HomeopatiaLiquida=response.data.homeopatiaLiquida;
        this.viewModel.NaoDeduzirVeiculo=response.data.deduzirQtdeVeiculo
        this.viewModel.ConverteVolumeEmbalagem= response.data.converteVolumeEmbalagem;
        this.viewModel.CalculoEmbalagem= response.data.calculoEmbalagem;
        this.viewModel.Uso= response.data.uso;
        this.viewModel.InativarForma= response.data.inativarForma;
        this.viewModel.LabEspCustos= response.data.labEspCustos;
        this.viewModel.Imagem= "data:image/jpeg;base64,".concat(response.data.imagem);
        this.viewModel.TipoUso= response.data.tipoUso;
        this.viewModel.POP= response.data.procedimentoOperacionalPadrao;
        this.viewModel.ImprimeCamposAnaliseProduto= response.data.imprimeCamposAnaliseProduto;
        this.viewModel.Validade= response.data.validade;
        this.viewModel.Quantidade= response.data.mlGotas;
        this.viewModel.Manipulador= response.data.manipuladorID;
        this.viewModel.FormulasHora= response.data.quantidadeFormulasHora;
        this.viewModel.FatorPerda=response.data.fatorPerda;
        this.viewModel.DescricaoRotulo= response.data.descricaoRotulo;
        this.viewModel.QspMinimo= response.data.percentualQspMinimo;
        this.viewModel.Veiculo= response.data.veiculoID;
        this.viewModel.PesagemMonitoradaForma= response.data.ativaPesagemForma;
        this.viewModel.CalcularDensidade= response.data.calcularDensidade;
        this.viewModel.ImprimirUnidadeRotulo= response.data.imprimirUnidadeRotulo;
        this.viewModel.SelecionarVolumeAutomatico=response.data.selecionaVolumeAutomatico;
        this.viewModel.AplicaFatorPerdaQSP= response.data.ativaFatorPerdaQSP;
        this.viewModel.FormaFarmaceuticaEnsaio= response.data.formaFarmaceuticaEnsaio;
        this.viewModel.valorMinimo= response.data.valorMinimoForma;
        this.viewModel.valorMaximo= response.data.custoAdicionalForma;
        this.viewModel.Ncm= response.data.ncmID;
        this.viewModel.faixaValor= response.data.formaFarmaceuticaFaixa;
        this.viewModel.margemValor=response.data.formaFarmaceuticaMargem;
    })
   }
  },
  watch: {
    tabIndexComponent() {
      this.$emit("update:tabIndex", this.tabIndexComponent);
    },
  },
};
</script>
