<template>
  <section>
    <div>
      <ListEssays  v-model="value"/>
    </div>
  </section>
</template>

<script type="module">
import ListEssays from "@core/components/commons/essayForm/ListEssays.vue";

export default {
  name: "SupplementaryInformationPharmaceuticals",
  components: {
    ListEssays,
  },
  props: {
    showFormCreate: {
      type: Boolean,
    },
    tabIndex: {
      type: Number,
    },
    value: {
      required: true,
      type:Object,
    }
  },
  methods: {
    changeRoutes(id) {
      const url = `/farmaceuticas/ensaios/edit/${id}`;
      history.pushState(null, null, url);
    },
  },
  watch: {
    tabIndex() {
      if (this.tabIndex !== 1) {
        const url = `/farmaceuticas/edit/${1}`;
        history.pushState(null, null, url);
      }
    },
  },
};
</script>
